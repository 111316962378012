import { defineStore } from 'pinia'
import { post } from '../service/api'
import router from '../router'

const baseUrl = `${process.env.VUE_APP_API_ROOT}`

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user:  JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        returnUrl: null
    }),
    actions: {
        async login(username, password, cb) {
            const bUser = { 
                usuario: username, 
                senha : password
            }
            try {
                const user = await post(`${baseUrl}/auth/login`, bUser)
                if(!user.sts) {
                    cb(`Verifique usuario ou senha, acesso inválido!`)
                    router.push('/login')
                } else {
                    if(!user.data.grupos.includes('web')) {
                        cb(`Usuario sem premissão de visualização WEB!`)
                    } else {
                        this.token = user.data.token
                        localStorage.setItem('token', user.data.token)
                        localStorage.setItem('user', JSON.stringify(user.data))
                        this.user = user.data;
                        router.push('/')
                    }
                }
            } catch(err) {
                cb(`Login inválido ${err}`)
                router.push('/login')
            }
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            router.push('/login');
        }
    }
});