import { ObjectID } from 'bson'

class CheckList {
    constructor() {
      this._id = new ObjectID()
      this.nome = ''
      this.objetivo = ''
      this.lista = []
      this.observacao = ''
      this.auth_superior = []
      this.permissao = []
    }

    fromObj(obj) {
        this._id            = obj._id
        this.nome           = obj.nome
        this.objetivo       = obj.objetivo
        this.lista          = obj.lista ? obj.lista : []
        this.observacao     = obj.observacao
        this.permissao      = obj.permissao ? obj.permissao : []
        this.auth_superior  = obj.auth_superior ? obj.auth_superior : []
        return this
    }

    valid() {
        const resp = {sts: false, data: ''}
        if (!this.nome) {
            resp.data = `NOME obrigatorio`
        } else if (!this.objetivo) {
            resp.data = `Objetivo obrigatorio`
        } else if (!this.lista) {
            resp.data = `Lista obrigatorio`
        } else if (!this.permissao) {
            resp.data = `Permissoes obrigatorio`
        } else {
            resp.sts = true
        }
        return resp
    }
}  
export {CheckList}
