<template>
        <ion-content>
        <div v-if="questions">
            <flow-form 
                ref="flowform" 
                v-bind:language="language" 
                v-on:complete="onComplete"
                v-bind:progressbar="false" >
                
                <question 
                    v-for="(question, index) in questions" 
                    v-bind="question" 
                    v-bind:key="'m' + index" 
                    v-model="question.model"
                />
                
                <template v-slot:complete>
                    <div class="f-section-wrap">
                    {{JSON.stringify(questions)}}
                    </div>
                </template>
            </flow-form>
        </div>
        </ion-content>
</template>
  
<script>
    import { IonContent } from '@ionic/vue'
    import {get} from '../service/api'
    import { FlowForm, Question, /*QuestionModel, QuestionType,*/ /*ChoiceOption,*/ LanguageModel } from '@ditdot-dev/vue-flow-form'

    export default {
        name: 'checkFlow',
        components: {FlowForm,Question,IonContent},
        props: {
            questions: { type: Array,default: () => [] }
        },
        data() {
            return {
                loading: false,
                completed: false,
                show: false,
                language: new LanguageModel({
                    multipleChoiceHelpTextSingle: "",
                    pressEnter: "",
                    skip: "Proximo",
                    thankYouText : "Tarefa realizada",
                    submitText: "Salvar",
                    successText: "",
                    placeholder: "",
                    longTextHelpText: "",
                    invalidPrompt : "Preenchimento inválido",
                }),
            }
        },
        async mounted() {
            for(let q of this.questions) {
                if(q.api) {
                    const resp = await get(`${q.api}`)
                    if (resp.sts) {
                        q.options = resp.data
                        this.$refs.flowform.reset()
                    } else {
                        alert(resp.data)
                    }
                }
            }
        },
        methods: {
            onComplete(completed) {
                this.completed = completed
                this.$refs.flowform.submitted = true
                alert(JSON.stringify(this.getData()))
            },
            getData() {
                const data = {questions: [],answers: []}
                this.questions.forEach(question => {
                if (question.title) {
                    let answer = question.model
                    if (Array.isArray(answer)) {
                    answer = answer.join(', ')
                    }
                    data.questions.push(question.title)
                    data.answers.push(answer)
                }
            })
    
        return data
      },

        }
    }
</script>
  
<style scoped>
    @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
    @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';
</style>