import {get, post, del, edit} from '../service/api'
import {Realizados} from '../model/realizados'

const api_url = `${process.env.VUE_APP_API}/realizados`


const realizadosCtrl = {
    fromObj: (from) => (new Realizados()).fromObj(from),

    novo: async () => new Realizados(),
    
    //data_fim>=2022-11-25
    filter: async (filter) => {
        const lista = []
        const resp = await get(`${api_url}?${filter}`)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new Realizados().fromObj(check))
            }
        }
        return lista  
    },
    obter: async (id) => {
        const lista = []
        const resp = await get(`${api_url}?_id=${id}`)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new Realizados().fromObj(check))
            }
        }
        return lista  
    },
    
    listar: async () => {
        const lista = []
        const resp = await get(api_url)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new Realizados().fromObj(check))
            }
        }
        return lista
    },

    listar_unico: async (campo) =>  await get(`${api_url}/distinct/${campo}`),
    
    salvar: async (grupo) => {
        const valido = grupo.valid()
        if (valido.sts) {
            return await post(api_url, grupo)
        } else {
            return valido
        }
    },
    
    editar: async (id, novo) => {
        const resp = await edit(`${api_url}/${id}`, novo)
        return resp
    },

    deletar: async (id) => {
        const resp = await del(`${api_url}/${id}`)
        return resp
    }

}

export {realizadosCtrl}
