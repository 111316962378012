import { ObjectID } from 'bson'

class Realizados {
    constructor() {
      this._id           = new ObjectID()
      this.op           = {}
      this.atividade    = ''
      this.atv_id       = ''
      this.data_inicio  = ''
      this.superior     = []
      this.data_fim     = ''
      this.tag_busca    = ''
      this.tag_conclusao = ''
      this.formulario   = []
    }

    fromObj(obj) {
        this._id             = obj._id
        this.op             = obj.op
        this.atividade      = obj.atividade
        this.superior       = obj.superior ? obj.superior : []
        this.data_inicio    = obj.data_inicio
        this.data_fim       = obj.data_fim
        this.tag_busca      = obj.tag_busca
        this.tag_conclusao  = obj.tag_conclusao
        this.formulario     = obj.formulario
        this.atv_id         = obj.atv_id
        return this
    }

    valid() {
        const resp = {sts: false, data: ''}
        if (!this.op) {
            resp.data = `Operador obrigatorio`
        } else if (!this.atividade) {
            resp.data = `Atividade obrigatorio`
        } else if (!this.data_inicio) {
            resp.data = `Data inicio obrigatorio`
        } else if (!this.data_fim) {
            resp.data = `Data fim obrigatorio`
        } else if (!this.formulario) {
            resp.data = `Formulario obrigatorio`
        } else {
            resp.sts = true
        }
        return resp
    }
}  
export {Realizados}
