import {get, post, del, edit} from '../service/api'
import {CheckItem} from '../model/checkitem'

const api_url = `${process.env.VUE_APP_API}/checkitem`


const checkItemCtrl = {
    fromObj: (from) => (new CheckItem()).fromObj(from),

    novo: async () => new CheckItem(),
    
    obter: async (id) => {
        const lista = []
        const resp = await get(`${api_url}?id=${id}`)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new CheckItem().fromObj(check))
            }
        }
        return lista  
    },
    
    listar: async () => {
        const lista = []
        const resp = await get(api_url)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new CheckItem().fromObj(check))
            }
        }
        return lista
    },
    
    salvar: async (checklist) => {
        const valido = checklist.valid()
        if (valido.sts) {
            const existe = await get(`${api_url}?id=${checklist.id}`)
            if (existe.sts) {
                if( existe.data.length > 0 ) {
                    return { sts: false, data: `id ${checklist.id} ja existe` }
                } else {
                    return await post(api_url, checklist)
                }
            }
            return existe
        } else {
            return valido
        }
    },
    
    editar: async (id, novo) => {
        const valido = novo.valid()
        if (valido.sts) {
            const resp = await edit(`${api_url}/${id}`, novo)
            return resp
        } else {
            return valido
        }
    },

    deletar: async (id) => {
        const resp = await del(`${api_url}/${id}`)
        return resp
    }

}

export {checkItemCtrl}
