import {get, post, del, edit} from '../service/api'
import {PermissaoGrupo} from '../model/permissaoGrupo'

const api_url = `${process.env.VUE_APP_API}/grupos`


const permissaoGrupoCtrl = {
    fromObj: (from) => (new PermissaoGrupo()).fromObj(from),

    novo: async () => new PermissaoGrupo(),
    
    obter: async (id) => {
        const lista = []
        const resp = await get(`${api_url}?id=${id}`)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new PermissaoGrupo().fromObj(check))
            }
        }
        return lista  
    },
    
    listar: async () => {
        const lista = []
        const resp = await get(api_url)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new PermissaoGrupo().fromObj(check))
            }
        }
        return lista
    },
    
    salvar: async (grupo) => {
        const valido = grupo.valid()
        if (valido.sts) {
            const existe = await get(`${api_url}?id=${grupo.id}`)
            if (existe.sts) {
                if( existe.data.length > 0 ) {
                    return { sts: false, data: `id ${grupo.id} ja existe` }
                } else {
                    return await post(api_url, grupo)
                }
            }
            return existe
        } else {
            return valido
        }
    },
    
    editar: async (id, novo) => {
        const valido = novo.valid()
        if (valido.sts) {
            const resp = await edit(`${api_url}/${id}`, novo)
            return resp
        } else {
            return valido
        }
    },

    deletar: async (id) => {
        const resp = await del(`${api_url}/${id}`)
        return resp
    }

}

export {permissaoGrupoCtrl}
