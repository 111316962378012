import {post, get} from '../service/api'
import {Upload} from '../model/upload'

const api_url = `${process.env.VUE_APP_UPLOAD}`


const uploadCtrl = {
    
    fromObj: (from) => (new Upload()).fromObj(from),

    novo: async () => new Upload(),

    
    lista: async (caption) => {
        try {
         return await get(`${api_url}/caption/${caption}`)
        } catch(err) {
         return {sts: false, data: err}
        }
         
     },
    

     src: async(filename) => {
        return `${api_url}/image/${filename}`
     },
     
     show: async (filename) => {
        try {
         return await get(`${api_url}/image/${filename}`)
        } catch(err) {
         return {sts: false, data: err}
        }
         
     },

    
    salvar: async (formData) => {
       try {
        const resp =  await post(`${api_url}`, formData, {headers: {"Content-Type": "multipart/form-data"}})
        return {sts: resp.sts, data: ''}
       } catch(err) {
        return {sts: false, data: err}
       }
        
    },
    

}

export {uploadCtrl}
