<template>
    <ion-header>
      <ion-toolbar>
        <ion-title> Atividades </ion-title>
        <ion-row>
            <ion-searchbar show-clear-button="always" placeholder="ID..." v-model="filter_obj.id"></ion-searchbar>
        </ion-row>
        
        <ion-row> 
            <ion-col size="6">
                <ion-select placeholder="Atividades" interface="popover" v-model="filter_obj.lista">
                    <ion-select-option v-for="itm in itemsLista" :key="itm" :value="itm">{{ itm }}
                    </ion-select-option>
                </ion-select>    
            </ion-col>    
            <ion-col size="6">
                <input v-model="filter_obj.date" type="date" />
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6">
                <ion-searchbar show-clear-button="always" placeholder="Operador..." v-model="filter_obj.op"></ion-searchbar>
            </ion-col>
            <ion-col size="6">
                <ion-button fill="clear" @click="search()">Procurar</ion-button>
            </ion-col>
       </ion-row> 
    </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
        <ion-card v-for="atv in atividades" :key="atv.id">
            <ion-card-header>
                <ion-card-title>{{atv.atividade}}   ( {{atv.tag_busca}} ) </ion-card-title>
                <ion-card-subtitle>{{atv.op.id}}-{{atv.op.nome}} - {{fmtData(atv.data_fim)}} {{atv.tag_conclusao}}</ion-card-subtitle>
                
                <ion-card-content v-if="atv.superior.length > 0">
                    <ion-list>
                        <ion-item v-for="(sup, index) in atv.superior" v-bind:key="'m' + index">
                            {{ fmtData(sup.data) }} - {{sup.id}}/{{sup.nome}} - {{sup.obs}}
                        </ion-item>
                    </ion-list> 
                </ion-card-content>
                
                
                <ion-button @click="openModal(atv._id)">
                    <ion-icon :icon="images"></ion-icon>
                </ion-button>
                <ion-button @click="pdfReport(atv)">
                    <ion-icon :icon="document"></ion-icon>
                </ion-button>
                <ion-button @click="mudaDecicao(atv)">
                    <ion-icon :icon="atv.superior.length > 0 ? lockClosed : lockOpen"></ion-icon>
                </ion-button>
            </ion-card-header>

        </ion-card>                  
    </ion-content>
    
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="primary" @click="cancel">Fechar</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>

</template>

<script>
import {
    IonButton,modalController,
    IonTitle,IonToolbar,IonHeader,IonButtons, IonContent,
    IonSearchbar,
    IonCol,
    IonRow,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCard,
    IonFooter,
    IonSelect,
    IonSelectOption,
    alertController 
} from '@ionic/vue';
import { images, document, lockClosed, lockOpen } from 'ionicons/icons'
import { defineComponent, ref, onBeforeMount, onMounted } from 'vue'
import {  realizadosCtrl } from "../controller/realizadosController"
import {  checkListCtrl } from "../controller/checkListController"
import {operadorCtrl} from "../controller/operadorController"

import uploadComponent  from "../components/upload.vue"
import pdfview from '../components/pdf.vue'

export default defineComponent({
name: 'modalOperador',
props: ['send'],
components: {
    IonButton,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonContent,
    IonSearchbar,
    IonCol,
    IonRow,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCard,
    IonFooter,
    IonSelect,
    IonSelectOption
},

setup() {    
    const atividades = ref([])
    const sel_atvs = ref(null)
    let selectList = ref([])
    let rpt = ref(false)
    let itemsLista = ref([])
    const filter_obj = ref({ lista: '', date:'', op: '', id: ''})
   

    const mudaDecicao = async (atv) => {
        const alert = await alertController.create({
          header: 'Liberação Superior',
          buttons: ['OK'],
          inputs: [
            {name: 'id', placeholder: 'ID',},
            {name: 'senha',  placeholder: 'Senha', type:"password"},
            {name: 'observacao',  placeholder: 'Observação', type:"text"},
          ],
        });
        await alert.present()
        const result = await alert.onDidDismiss();
        const resultData = result.data.values;
        if(resultData['id']  && resultData['senha'] && resultData['observacao']) {
            const op = await operadorCtrl.login(resultData['id'],resultData['senha'])
            if(op.length > 0) {
                const list_ativi = await checkListCtrl.obter(atv.atv_id)
                for(let sup of list_ativi[0].auth_superior) {
                    if (op[0].grupos.includes(sup.id)) {
                        atv.superior.push({id: op[0].id, nome: op[0].nome, data: new Date(), obs: resultData['observacao']})
                        await realizadosCtrl.editar(atv._id, {superior: atv.superior })
                        await search()
                        break
                    }
                }
                
            } else {
                alert('Superior não autorizado ou falta observação!!!')
            }
        }
    }

    const pdfReport = async (itm) => {
        sel_atvs.value = itm
        await openModalPdf()
       
    }

    const listCheck = async () => {
        // Listar nome de atividade distinto
        itemsLista.value = [] 
        const resp = await realizadosCtrl.listar_unico('atividade')
         if(resp.sts) {
            itemsLista.value = resp.data
         }  
    }

    const fmtData =  (dtentrada) => {
        const dt = new Date(dtentrada)
        return dt.toLocaleString("pt-Br",{dateStyle: "short",timeStyle: "short",timeZone: "America/Sao_Paulo"})
    }        

    const changeList = async (novo) => {
        filter_obj.value.lista = novo._id
    }

    const cancel = () => {
        return modalController.dismiss(null, 'cancel');
    }
    
    const confirm = async() => {
        return modalController.dismiss(``, 'confirm');
    }

    const search = async() => {
        let query = ''
        atividades.value  = []
        if (filter_obj.value.lista) {
            query = `${query}atividade=${filter_obj.value.lista}`
        } 
        if (filter_obj.value.id) {
            query = `${query}${query.length > 0 ? '&' : ''}tag_busca=/${filter_obj.value.id}/`
        }
        if (filter_obj.value.date) {
            const date = new Date(filter_obj.value.date)
            date.setDate(date.getDate() + 1)
            query = `${query}${query.length > 0 ? '&' : ''}data_fim>=${filter_obj.value.date}&data_fim<=${date.toISOString()}`
        }
        if (filter_obj.value.op) {
            query = `${query}${query.length > 0 ? '&' : ''}op.id=${filter_obj.value.op}`
        } 
        if (query) {
            atividades.value = await realizadosCtrl.filter(query)
            atividades.value.sort((a,b) => {
                if (a.data_fim < b.data_fim) {
                    return 1
                }
                if (a.data_fim > b.data_fim) {
                    return -1
                }   
            })
        }
    }
    
    const openModal = async (atv_id) => {
      const id_modal = 'UploadFiles'
      console.log(`ABRIR MODAL ${atv_id}`)
      let modal = await modalController.create({component: uploadComponent, componentProps: { atv_id: atv_id}, id: id_modal })
      modal.present()
      const { data, role } = await modal.onWillDismiss();
      await modalController.dismiss(undefined, undefined, id_modal)
      return {sts: role, data: data}  
    }

    const openModalPdf = async () => {
      const id_modal = 'PDFReport'
      let modal = await modalController.create({component: pdfview, componentProps: { sel_atvs: sel_atvs.value}, id: id_modal })
      modal.present()
      const { data, role } = await modal.onWillDismiss();
      await modalController.dismiss(undefined, undefined, id_modal)
      return {sts: role, data: data}  
    }


    onBeforeMount(async () => {
        const data = new Date().toLocaleDateString('sv')
        filter_obj.value.date = data
        await search()
    })
    onMounted(async () => {
      await listCheck()
    })

    return {
        cancel,
        confirm,
        atividades,
        filter_obj,
        search,
        openModal,
        images,
        itemsLista,
        selectList,
        changeList,
        pdfReport,
        document,
        sel_atvs,
        rpt,
        openModalPdf,
        fmtData,
        mudaDecicao,
        lockClosed,
        lockOpen
    }
},

})


</script>