<template>
        <ion-header>
          <ion-toolbar>
            <ion-title>{{selectItem.tagline}} - {{selectItem.title}} </ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <p>
            {{selectItem.subtitle}}
          </p>
          <ion-list>
            <ion-item v-for="(itm, index) in selectItem.options" :key="index">
              <ion-label>{{ itm.label }}</ion-label>
                <ion-select v-model="itm.value" placeholder="CHECKITEM" interface="popover">
                  <ion-select-option v-for="itm in items" :key="itm.nome" :value="itm.id">{{ itm.tagline }} - {{ itm.title }}
                  </ion-select-option>
                </ion-select>
            </ion-item>
          </ion-list>
          <ion-buttons>
              <ion-button :strong="true" @click="confirm(selectItem)">Salvar</ion-button>
              <ion-button @click="cancel">Fechar</ion-button>
          </ion-buttons>
        </ion-content>
</template>

<script>
    import {
        IonSelect,IonSelectOption,IonButton,IonLabel,IonItem,IonList,modalController,
        IonTitle,IonToolbar,IonHeader,IonButtons, IonContent 
    } from '@ionic/vue';
    import { defineComponent} from 'vue';

    export default defineComponent({
    name: 'modalAtiviade',
    props: ['selectItem','items'],
    components: {
        IonSelect, 
        IonSelectOption,
        IonButton,
        IonLabel,
        IonItem,
        IonList,
        IonTitle,
        IonToolbar,
        IonHeader,
        IonButtons,
        IonContent
    },
    
    setup() {    
        const cancel = () => {
            return modalController.dismiss(null, 'cancel');
        }
        const confirm = (editItem) => {
            return modalController.dismiss(editItem, 'confirm');
        }
        return {
            cancel,
            confirm
        }
    },
    
    })


</script>