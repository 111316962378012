class PermissaoGrupo {
    constructor() {
      this.id = ''
      this.descricao = ''
    }

    fromObj(obj) {
        this.id        = obj.id
        this.descricao = obj.descricao
        return this
    }

    valid() {
        const resp = {sts: false, data: ''}
        if (!this.id) {
            resp.data = `ID obrigatorio`
        } else {
            resp.sts = true
        }
        return resp
    }
}  
export {PermissaoGrupo}
