<template>
  
   <ion-header>
      <ion-toolbar>
        <ion-title> Arquivos enviados </ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content>
        <ion-list>
            <ion-item  v-for="(img, index) in imgs.images" v-bind:key="index">
                <ion-thumbnail slot="start">
                    <ion-img :src="`${img}`"></ion-img>
                </ion-thumbnail>
                <ion-button slot="end" fill="clear" @click="openImg(img)">
                    <ion-icon slot="icon-only"  :icon="eyeOutline"></ion-icon>
                </ion-button>
            </ion-item>
            <ion-item  v-for="(audio, index) in imgs.audios" v-bind:key="index">
                Audio
                <ion-button slot="end" fill="clear" @click="playaudio(audio)">
                    <ion-icon slot="icon-only"  :icon="playOutline"></ion-icon>
                </ion-button>
            </ion-item>
        </ion-list>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="primary" @click="cancel">Fechar</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>

</template>


<script>
    import { uploadCtrl } from "../controller/uploadController"
    import { IonContent, IonList, IonItem, IonThumbnail, IonImg, IonButton, IonHeader, IonFooter, modalController } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { recording, playOutline, eyeOutline, musicalNoteOutline } from 'ionicons/icons'
   
    export default defineComponent({
        name: 'modalUpload',
        props: ['atv_id'],
        components: { IonContent,IonList, IonItem, IonThumbnail, IonImg, IonButton, IonHeader, IonFooter },
        
        setup(props) { 
            const imgs = ref([])
         
            const cancel = () => {
                return modalController.dismiss(undefined, 'cancel');
            }
        
            const playaudio = async(aud) => {
                const baseAudio = await getBase64Image(aud)
                const audioRef = new Audio(baseAudio)
                audioRef.oncanplaythrough = () => audioRef.play()
                audioRef.load()
            }

            const getBase64Image = async (url) => {
                const response = await fetch(url);
                const blob = await response.blob();
                const reader = new FileReader();
                await new Promise((resolve, reject) => {
                    reader.onload = resolve;
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
                return reader.result
            }

            const openImg = (url) => {
                window.open(url, '_blank')
            }

            onMounted( async () => {
                imgs.value = { recording: false, images:[], audios: [] }
                const images = await uploadCtrl.lista(`Image_${props.atv_id}`)
                const audios = await uploadCtrl.lista(`Audio_${props.atv_id}`)
                if(images.sts) {
                    for (let im of images.data.files) {
                        imgs.value.images.push( await uploadCtrl.src(im.filename) ) 
                    }
                }
                if(audios.sts) {
                    for (let aud of audios.data.files) {
                        imgs.value.audios.push( await uploadCtrl.src(aud.filename) ) 
                        
                    }
                }

            })
            return {cancel, imgs, playaudio, recording, playOutline, eyeOutline, openImg, musicalNoteOutline}
        }
    });
</script>