<template>
    <ion-header>
      <ion-toolbar>
        <ion-title> Solicitacao de Acesso </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        
        <div v-if="modo === 'mobile'">
          <ion-item >
            <ion-label>ID-CRACHA</ion-label>
            <input maxlength="8" required v-model="operador.id" type="text" />
          </ion-item>
          <ion-item >
            <ion-label>Nome</ion-label>
            <input maxlength="20" required v-model="operador.nome" type="text" />
          </ion-item>
          <ion-item >
            <ion-label>Senha</ion-label>
            <input maxlength="20" required v-model="operador.senha" type="password" />
          </ion-item>
        </div>
        
        <div v-if="modo === 'desktop'">
          <ion-grid>
            
            <ion-label>Grupos de acesso</ion-label>
            
            <br />
            <br />

            <ion-row>
              <input maxlength="10" required v-model="opcao" type="text" />
              <ion-button :disabled="!opcao" shape="round" @click="addGrupo(opcao)">+</ion-button>
              <ion-button :disabled="!opcao" shape="round" @click="remGrupo(opcao)">-</ion-button>
              <ion-button :disabled="resultado_busca.length === 0 || !opcao" shape="round" @click="addOperador(opcao)">V</ion-button>
            </ion-row>
            <ion-row>
              <ion-chip v-for="(opc, index) in grupos" v-bind:key="'m' + index" @click="opcao = opc.id">{{opc.id}}
              </ion-chip>
            </ion-row>

            <br />
            <br />

            <ion-label>Operador</ion-label>
            <ion-row>
              <ion-col>
                <ion-searchbar v-model="buscar_operador" animated="true" placeholder="ID"></ion-searchbar>
              </ion-col>
              <ion-col>
                <ion-button @click="procurar()" shape="round">Procurar</ion-button>
              </ion-col>
            </ion-row>
            
            <ion-row>
              <div v-for="(resultado, index) in resultado_busca" v-bind:key="'m' + index">
                
                <ion-item>
                  <ion-button @click="delOper(resultado.id)" shape="round">-</ion-button>
                  <ion-label> {{resultado.id}} - {{resultado.nome}} </ion-label>
                  <ion-chip v-for="(opc, index) in resultado.grupos" v-bind:key="'m' + index" @click="remGrpOp(index)">{{opc}}</ion-chip>
                </ion-item>
                <ion-label>{{resultado.code}}</ion-label>
              
              </div>
            </ion-row>
            
          
          </ion-grid>
        </div>      
    </ion-content>
    
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button v-if="resultado_busca.length > 0" @click="generate_code()" shape="round">Gerar codigo</ion-button>
            <ion-button color="primary" @click="cancel">Fechar</ion-button>
            <ion-button color="primary" @click="salvarGeral()" shape="round" >Salvar</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>

</template>

<script>
import {
    IonButton,IonLabel,IonItem,modalController,
    IonTitle,IonToolbar,IonHeader,IonButtons, IonContent, IonSearchbar,
    IonRow,
    IonChip,
    IonCol,
    IonGrid,
    IonFooter 
} from '@ionic/vue';
import { defineComponent, ref, onBeforeMount} from 'vue'
import { operadorCtrl } from "../controller/operadorController"
import {  permissaoGrupoCtrl } from "../controller/permissaoGrupoController"
import {  PermissaoGrupo } from "../model/permissaoGrupo"


export default defineComponent({
name: 'modalOperador',
props: ['modo'],
components: {
    IonButton,
    IonLabel,
    IonItem,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonContent,
    IonSearchbar,
    IonRow,
    IonChip,
    IonCol,
    IonGrid,
    IonFooter
},

setup(props) {    
    const opcao = ref('')
    const grupos = ref([])
    const operador = ref({})
    const buscar_operador = ref(``)
    const resultado_busca = ref([])

    const cancel = () => {
        return modalController.dismiss(null, 'cancel');
    }
    
    const confirm = async() => {
        const resp = await operadorCtrl.salvar(operador.value)
        return modalController.dismiss(resp, 'confirm');
    }

    const addGrupo = async (opc) => {
      const existe = grupos.value.findIndex(object => object.id.toUpperCase() === opc.toUpperCase())
      if (existe === -1) {
        grupos.value.push(new PermissaoGrupo().fromObj({id: opc.toLowerCase(), descricao: opc.toLowerCase()}))
      }
      opcao.value = ''
    }
    
    
    const remGrupo = async (opc) => {
      const idx = grupos.value.findIndex(object => object.id.toUpperCase() === opc.toUpperCase())
      grupos.value.splice(idx, 1)
      opcao.value = ``
    }

    const procurar = async () => { 
      if(buscar_operador.value) {
        resultado_busca.value = await operadorCtrl.obter(buscar_operador.value)
        buscar_operador.value = ``
      }
    }

    const addOperador = async (opc) => {
      if(opc){
        const alterou = grupos.value.findIndex(object => object.id.toUpperCase() === opc.toUpperCase())
        if(alterou > -1) {
          const existe = resultado_busca.value[0].grupos.findIndex(object => object.toUpperCase() === opc.toUpperCase())
          if (existe === -1) {
            resultado_busca.value[0].grupos.push(opc.toLowerCase())
          }
        }
      }
    }

    const delOper = async (id) => {
      await operadorCtrl.deletar(id)
      resultado_busca.value = []
    }

    const remGrpOp = async (idx) => resultado_busca.value[0].grupos.splice(idx, 1)

    
    const salvarGeral = async () => {
      if(props.modo === 'mobile') {
        const res = await operadorCtrl.salvar(operador.value)
        if(res.sts) {
          alert('Acesso solicitado')
          operador.value = await operadorCtrl.novo()
          cancel()
        } else {
          alert(res.data)
        }
      } else {
        if(resultado_busca.value.length > 0) {
          await operadorCtrl.editar(resultado_busca.value[0]._id, {grupos: resultado_busca.value[0].grupos, code: resultado_busca.value[0].code})
        }
        const refDb = await await permissaoGrupoCtrl.listar()
        for (let r of refDb) {
          await permissaoGrupoCtrl.deletar(r._id)
        }
        for (let u of grupos.value) {
          await permissaoGrupoCtrl.salvar(u)
        }
        operador.value = await operadorCtrl.novo()
        grupos.value = await permissaoGrupoCtrl.listar()
        buscar_operador.value = ``
        opcao.value = ''
        alert('Alterado')
        }
    }
    
    const genRand = (len) => {
        return Math.random().toString(36).substring(2,len+2);
    }

    const generate_code = () => {
      resultado_busca.value[0].code = genRand(6)
    }

    onBeforeMount(async () => {
      operador.value = await operadorCtrl.novo()
      grupos.value = await permissaoGrupoCtrl.listar()
      
    })

    return {
        cancel,
        confirm,
        operador,
        opcao,
        grupos,
        addGrupo,
        remGrupo,
        procurar,
        buscar_operador,
        resultado_busca,
        addOperador,
        remGrpOp,
        salvarGeral,
        genRand,
        generate_code,
        delOper
    }
},

})


</script>