<template>
  <ion-content>

    <swiper :modules="modules" :autoplay="false" :keyboard="true" :pagination="true" :scrollbar="true" :zoom="true">
        
        <swiper-slide>
            
          <ion-grid>
            <ion-row>
              <ion-col size="6">
                  <!--FORMAULARIO ITENS DE CHECK-->
                  <ion-content>          
                  <ion-item>
                    <ion-label>ID Unico:</ion-label>
                    <input :disabled="(modo === 'Salvar')" maxlength="6" required v-model="novo.id" type="text" />
                  </ion-item>
  
                  <ion-item>
                    <ion-label>Tipo:</ion-label>
                    <ion-select required interface="popover" v-model="novo.type" placeholder="">
                      <ion-select-option value="Text">Texto Curto</ion-select-option>
                      <ion-select-option value="LongText">Texto Longo</ion-select-option>
                      <ion-select-option value="Number">Numero</ion-select-option>
                      <ion-select-option value="Date">Data</ion-select-option>               
                      <ion-select-option value="MultipleChoice">Opções</ion-select-option>
                      <ion-select-option value="Dropdown">API</ion-select-option>
                      <ion-select-option value="SectionBreak">Aviso</ion-select-option>
                      <!-- <ion-select-option value="File">Arquivo</ion-select-option> -->
                    </ion-select>
                  </ion-item>
              
                  <ion-item v-if="novo.type === 'Dropdown'">
                    <!--TODO retornno api dever ser [{label:`` , value:``}]-->
                    <ion-label>URL API:</ion-label>
                    <input maxlength="60" required v-model="novo.api" type="text" />
                  </ion-item>
                  <ion-item v-if="novo.type === 'MultipleChoice'">
                    <ion-grid>
                      <ion-row>
                        <input maxlength="20" required v-model="opcao" type="text" />
                        <ion-button shape="round" @click="addOpcao(opcao)">+</ion-button>
                        <ion-button shape="round" @click="addDefault(`SN`)">SN</ion-button>
                      </ion-row>
                      <ion-row>
                        <ion-chip v-for="(opc, index) in novo.options" v-bind:key="'m' + index" @click="remOpcao(index)">{{opc.label}}
                        </ion-chip>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
              
                  <ion-item>
                    <ion-label>Atividade:</ion-label>
                    <input maxlength="20" required v-model="novo.tagline" type="text" />
                  </ion-item>
                  <ion-item>
                    <ion-label>Etapa:</ion-label>
                    <input maxlength="50" required v-model="novo.title" type="text" />
                  </ion-item>
                  
                  <ion-item>
                    <ion-label>Pergunta:</ion-label>
                    <ion-textarea maxlength="100" required v-model="novo.subtitle" type="text" />
                  </ion-item>
                  <div v-if="novo.type === 'Text' || novo.type === 'Number' || novo.type === 'Date'">
                    
                    <ion-item>
                      <ion-label>Mask:</ion-label>
                      <ion-textarea maxlength="20" required v-model="novo.mask" type="text" />
                    </ion-item>

                    <ion-item>
                      <ion-label>Cod.Barras {{ novo.barCode.type }}</ion-label>  
                      <ion-checkbox required v-model="novo.barCode.enable"></ion-checkbox>
                        <ion-item>
                          <ion-select :disabled="!novo.barCode.enable" placeholder="TipoCod" interface="popover" 
                            @ionChange="changeBar($event.detail.value)">
                            <ion-select-option v-for="(bar, index) in barList" v-bind:key="'m' + index" 
                            :value="bar">{{ bar }} 
                            </ion-select-option>
                          </ion-select>
                        </ion-item>
                      </ion-item>
                    
                    <ion-item>
                      <ion-label>Tam Maximo:</ion-label>
                      <ion-textarea maxlength="20" required v-model="novo.maxLength" type="number" />
                    </ion-item>
                  </div>
                  <ion-item>
                    <ion-checkbox required v-model="novo.required"></ion-checkbox>
                    <ion-label>Obrigatorio</ion-label>
                  </ion-item>
                  <ion-button expand="full" shape="round" @click="addItem(novo, modo)">{{modo}}</ion-button>
                
                </ion-content>
                <!--FIM FORMAULARIO ITENS DE CHECK-->
              </ion-col>
                
              <ion-col size="6">
                  <!--SELECT CHECKITEM BOTÔES DE AÇÂO-->
                  <ion-searchbar animated="true" show-clear-button="always" placeholder="Procurar..." :debounce="1000"
                    @ionChange="handleChange($event)">
                  </ion-searchbar>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-title>
                        <ion-select placeholder="CHECKITEM" interface="popover" @ionChange="changeItem($event.detail.value)">
                                <ion-select-option v-for="itm in items" :key="itm._id" :value="itm">{{ itm.tagline }} - {{ itm.title }}</ion-select-option>
                              </ion-select>
                      </ion-card-title>
  
                    </ion-card-header>
                    <ion-toolbar>
                      <ion-button  :disabled="selectItem.length === 0"    fill="clear" shape="round" @click="editItem()">Editar</ion-button>
                        <ion-button  :disabled="selectItem.length === 0"  fill="clear" shape="round" @click="delItem()">Remover</ion-button>
                        <ion-button  :disabled="selectItem.length === 0"  fill="clear" shape="round" @click="prevItem()">Preview</ion-button>
                        <ion-button  :disabled="selectItem.length === 0"  fill="clear" shape="round" @click="clearItem()">Limpar</ion-button>
                        <ion-button  :disabled="selectItem.length === 0 || selectList.length === 0" fill="clear" shape="round" @click="doItemToList(selectItem[0])">+LISTA</ion-button>
                  </ion-toolbar>            
                  </ion-card>
                  <ion-card>
                    <ion-list >
                              <ion-item>
                                <ion-label>De:   {{relation.from.nome}}</ion-label>
                              </ion-item>
                              <ion-item>
                                <ion-label>Para: {{relation.to.nome}}</ion-label>
                              </ion-item>
                            </ion-list>
                            <ion-button fill="clear" :disabled="!relation.from.id || !relation.to.id" @click="relationFlow()" >Relacionar</ion-button>
                  </ion-card>  
                <!--FIM SELECT CHECKITEM BOTÔES DE AÇÂO-->
              </ion-col>
            </ion-row>
          </ion-grid>
            
        </swiper-slide>
          
        <swiper-slide>
  
            <!--FORMULARIO LISTA DE CHECK -->
          <ion-card>
              
              <!-- SELECT BOX LISTAS -->
              <ion-card-header>
                <ion-card-title>
                  <ion-select placeholder="CHECKLIST" interface="popover" @ionChange="changeList($event.detail.value)">
                    <ion-select-option v-for="itm in itemsLista" :key="itm.nome" :value="itm">{{ itm.nome }} - {{ itm.objetivo }}
                    </ion-select-option>
                  </ion-select>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
            
                <ion-grid>
                  <ion-row size="12">
                    
                    <!-- FORMULARIO LISTA -->
                    <ion-col size="6">
                      <ion-item>
                        <ion-label>Nome:</ion-label>
                        <input maxlength="20" required v-model="novaLista.nome" type="text" />
                      </ion-item>
                      <ion-item>
                        <ion-label>Objetivo:</ion-label>
                        <ion-textarea maxlength="100" required v-model="novaLista.objetivo" type="text" />
                      </ion-item>
                      <ion-item>
                        <ion-label>Observacao:</ion-label>
                        <ion-textarea maxlength="100" required v-model="novaLista.observacao" type="text" />
                      </ion-item>
                    </ion-col>
                    
                    <!-- LISTA CONTROLES -->
                    <ion-col size="6">
                      <ion-list>
                        <ion-item>
                          <ion-button :disabled="selectList.length === 0" fill="clear" @click="editLista()">Editar</ion-button>
                          <ion-button :disabled="selectList.length === 0" fill="clear" @click="delLista()">Remover</ion-button>
                        </ion-item>
                        <ion-item>
                          <ion-button fill="clear" @click="addLista(novaLista, modoList)">{{modoList}}</ion-button>
                          <ion-button fill="clear" :disabled="selectList.length === 0" @click="clearLista()">Limpar</ion-button>
                          <ion-button fill="clear" :disabled="selectList.length === 0" shape="round" @click="prevLista()">Preview
                          </ion-button>
                        </ion-item>
                      </ion-list>
            
                      <!-- PERMISSOES DE GRUPOS -->
                      <ion-grid>
                        <ion-row>
                          <ion-select placeholder="Permissões" interface="popover" v-model="grupoSel">
                            <ion-select-option v-for="itm in listaGrupos" :key="itm.id" :value="itm">{{ itm.id }}
                            </ion-select-option>
                          </ion-select>
                          <ion-button fill="clear" shape="round" @click="addPermissao(grupoSel)">+</ion-button>
                        </ion-row>
                        <ion-row>
                          <ion-chip v-for="(opc, index) in novaLista.permissao" v-bind:key="'m' + index"
                            @click="remPermissao(index)">{{opc.id}}</ion-chip>
                        </ion-row>
                      </ion-grid>


                       <!-- PERMISSOES DE GRUPOS AUTORIZAÇÂO -->
                       <ion-grid>
                        <ion-row>
                          <ion-select placeholder="Autorização" interface="popover" v-model="grupoSelPerm">
                            <ion-select-option v-for="itm in listaGrupos" :key="itm.id" :value="itm">{{ itm.id }}
                            </ion-select-option>
                          </ion-select>
                          <ion-button fill="clear" shape="round" @click="addAutorizacao(grupoSelPerm)">+</ion-button>
                        </ion-row>
                        <ion-row>
                          <ion-chip v-for="(opc, index) in novaLista.auth_superior" v-bind:key="'m' + index"
                            @click="remAutorizacao(index)">{{opc.id}}</ion-chip>
                        </ion-row>
                      </ion-grid>

                      
                    </ion-col>
                  </ion-row>
                </ion-grid>        
              </ion-card-content>
            </ion-card>
            <!--FIM FORMULARIO LISTA DE CHECK -->
        </swiper-slide>
         
    </swiper>
    
    <!--CONTROLE DO FLOW -->
    <ion-card>
      <ion-card-header>
        <ion-toolbar>
          <ion-title>FLOW</ion-title>
          <ion-buttons slot="end">
            <ion-button :disabled="(selectList.length === 0 || ordenar)" color="primary" fill="solid" shape="round"
              @click="saveFlow()">
              Salvar</ion-button>
          </ion-buttons>
          <ion-item>
            <ion-label>Ordenar</ion-label>
            <ion-toggle :disabled="(selectList.length === 0)" v-model="ordenar"></ion-toggle>
          </ion-item>
        </ion-toolbar>
      </ion-card-header>
     
      <ion-card-content>
        <Sortable v-if="ordenar" @end="dragEvt" :list="selectList.lista" item-key="_id" tag="ion-list" :options="{
                                swapThreshold: 1,
                                animation: 150,
                                dragoverBubble: true,
                                ghostClass: 'ghost',
      
                              }">
      
          <template #item="{element}">
      
            <ion-list :key="element._id">
              <ion-item>
                <ion-label>{{ element.id.trim() }} - {{ element.tagline.trim() }} - {{ element.title.trim() }}  = {{ defFlow(element) }}</ion-label>
                
              </ion-item>
            </ion-list>
      
          </template>
        </Sortable>
      
        <ion-list v-if="!ordenar">
          <ion-item v-for="(itm, index) in selectList.lista" :key="itm._id">
      
            <ion-button shape="round" @click="editFromList(itm, index)">
              <ion-icon :icon="create"></ion-icon>
            </ion-button>
      
            <ion-button shape="round" @click="itemRmList(index)">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
      
            <ion-label>{{ itm.id.trim() }} - {{ itm.tagline.trim() }} - {{ itm.title.trim() }} = {{ defFlow(itm) }}</ion-label>
            <ion-checkbox v-if="Object.keys(itm.jump).length === 0" :value="itm" required @ionChange="changeCheck($event)">
            </ion-checkbox>
            <ion-button v-if="itm.options.length === 0 && Object.keys(itm.jump).length > 0" shape="round"
              @click="clearRela(itm)">Limpar</ion-button>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <!--FIM CONTROLE DO FLOW -->


  
    <!-- BOTOES DE OPCAO -->
  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button size="large">
      <ion-icon :icon="chevronUpCircle"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button @click="openUserModal">
        <ion-icon :icon="people"></ion-icon>
      </ion-fab-button>
      <ion-fab-button @click="openAtividadeModal">
        <ion-icon :icon="listCircle"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>
</template>

<script>
import { Sortable } from "sortablejs-vue3"
import {checkItemCtrl} from "../controller/checkItemController"
import {checkListCtrl} from "../controller/checkListController"
import {permissaoGrupoCtrl} from "../controller/permissaoGrupoController"
import { chevronUpCircle, people, listCircle, create, trash } from 'ionicons/icons'
import { defineComponent, ref, onMounted} from 'vue';
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/keyboard'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/zoom'
import '@ionic/vue/css/ionic-swiper.css'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue'
import flow from '../components/flow.vue'
import modalAtiviade from '../components/modal.vue'
import modalAcesso from '../components/acesso.vue'
import modalAtividade from '../components/atividade.vue'

import {
  IonGrid, 
  IonCol, 
  IonRow,
  IonSelect, 
  IonSelectOption,
  IonButton,
  IonLabel,
  IonItem,
  IonTextarea,
  IonCheckbox,
  IonChip,
  IonList,
  alertController,
  modalController,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonToolbar,
  IonContent,
  IonTitle,
  IonButtons,
  IonSearchbar,
  IonIcon,
  IonFabButton,
  IonFabList,
  IonFab,
  IonToggle
} from '@ionic/vue';

export default defineComponent({
  name: 'BasicDemo',
  components: {
    Swiper,
    SwiperSlide,
    IonGrid,
    IonCol,
    IonRow,
    IonSelect, 
    IonSelectOption,
    IonButton,
    IonLabel,
    IonItem,
    IonTextarea,
    IonCheckbox,
    IonChip,
    IonList,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonToolbar,
    IonContent,
    IonTitle,
    IonButtons,
    IonSearchbar,
    IonIcon,
    IonFabButton,
    IonFabList,
    IonFab,
    Sortable,
    IonToggle
  },
  
  setup() {
    let ordenar = ref(false)
    let items = ref([])
    let barList = ref([
      'UPC_A',
      'EAN_13',
      'QR_CODE',
      'CODE_128'
    ])
    let itemsLista = ref([])
    let novo = ref({})
    let novaLista = ref({})
    let selectItem = ref([])
    let selectList = ref([])
    let modo = ref('Incluir')
    let modoList = ref(`Incluir`)
    let opcao = ref('')
    let preview = ref(false)
    let disarm = ref(false)  
    let questions = ref({})
    let relation = ref({from:{id: '', texto:''}, to:{id: '', texto:''}})
    let grupoSel = ref('')  
    let grupoSelPerm = ref('')
    let listaGrupos = ref([]) 
   
    const dragEvt = (evt) => {
      const item = selectList.value.lista.splice(evt.oldIndex, 1)[0]
      selectList.value.lista.splice(evt.newIndex, 0, item)
    } 
    
    const defFlow = (element) => {
      let vlr = ''
      if(Object.prototype.hasOwnProperty.call(element.jump,'_other')){
        vlr = vlr + `  (Jump to ${element.jump['_other']}) `
      }
      for(let el of element.options) {
        if(Object.prototype.hasOwnProperty.call(element.jump,el.value)){
          vlr = vlr + `  (${el.label}->${el.referencia}) `
        }
      }
      return vlr
    }
    
    const editFromList = async (itm, idx) =>{ 
      await changeItem(checkItemCtrl.fromObj(itm)) 
      await itemRmList(idx) 
      await editItem()
    }
    
    const listItem = async () => {
      items.value = await checkItemCtrl.listar()
    }
    const listGrupos = async () => {
      listaGrupos.value = await permissaoGrupoCtrl.listar()
    }

    const addItem = async (salvar, op) => {
      let resp = {sts: false, data: {}}
      
      if (salvar.type !== 'MultipleChoice') {
        salvar.options = []
      }
      
      if (salvar.type !== 'Text' && salvar.type !== 'Number' && salvar.type !== 'Date') {
        salvar.maxLength = null
        salvar.mask = null
        salvar.placeholder = null
      } else {
        salvar.placeholder = salvar.mask
      }
      
      if (op === 'Incluir') {
        resp = await checkItemCtrl.salvar(salvar)
      } else if (op === 'Salvar') {
        resp = await checkItemCtrl.editar(salvar._id, salvar)
      } else if (op === 'Deletar') {
        resp = await checkItemCtrl.deletar(salvar._id)
      }    
      if (resp.sts) {
          aviso(`Ok`, `${op} -> concluido`)
          await listItem()
          await listGrupos()
          selectItem.value = []
          novo.value  = await checkItemCtrl.novo()
          modo.value  = 'Incluir'
        } else {
          await aviso(`Erro`, `${op} -> ${resp.data}`)
      }
    }
    
    const changeItem = async (novo) => {
      selectItem.value = [novo]
      preview.value = false
      modo.value = 'Incluir'
    }

    const changeBar = async (novo_tipo) => {
      novo.value.barCode.type = novo_tipo
      console.log(`MUDOU de ${novo.value} para ${novo_tipo}`)
    }
    
    const editItem = async () => {
      novo.value = selectItem.value[0]
      modo.value = 'Salvar'
    }

    const delItem = async () => {
      await addItem(selectItem.value[0],'Deletar')
      
    }

    const addOpcao = async (opc) => {
      const existe = novo.value.options.findIndex(object => object.label.toUpperCase() === opc.toUpperCase());
      if(existe === -1) {
        novo.value.options.push({label: opc.toLowerCase(), value: ''})
      }
      opcao.value = ''
    }

    const addDefault = async (op) => {
      if(op === `SN`) {
        await addOpcao(`SIM`)
        await addOpcao(`NÃO`)
      }
    }
    
    const addPermissao = async (opc) => {
      if(opc) {
        const existe = novaLista.value.permissao.findIndex(object => object.id === opc.id);
        if(existe === -1) {
          novaLista.value.permissao.push(opc)
        }
        grupoSel.value = ''
      }
    }
    
    const addAutorizacao = async (opc) => {
      if(opc) {
        const existe = novaLista.value.auth_superior.findIndex(object => object.id === opc.id);
        if(existe === -1) {
          novaLista.value.auth_superior.push(opc)
        }
        grupoSelPerm.value = ''
      }
    }

    const remOpcao = async (idx) => novo.value.options.splice(idx, 1)
    const remPermissao = async (idx) => novaLista.value.permissao.splice(idx, 1)
    const remAutorizacao = async (idx) => novaLista.value.auth_superior.splice(idx, 1)
    

    const prevItem = async () => {
      questions.value = selectItem.value
      await openPreview()
    }

    
    const clearItem = async () => {
      novo.value = await checkItemCtrl.novo()
      await listItem()
      await listGrupos()
      selectItem.value = [novo]
      preview.value = false
      modo.value = 'Incluir'
    }


    // Deletar uma lista
    const delLista = async () => {
      await addLista(selectList.value,'Deletar')
    }

    // Limpar relacionamento realizado
    const clearRela = (itm) => {
      const tem = selectList.value.lista.findIndex(obj => obj._id === itm._id) 
      if(tem > -1) {
        selectList.value.lista[tem].jump = {}
      }
    }

    // Relacionamento das atividades em um fluxo
    const relationFlow = async () => {
      if ( relation.value.from.id && relation.value.to.id ) {
        const tem = selectList.value.lista.findIndex(obj => obj.id === relation.value.from.id) 
        if(tem > -1) {
          selectList.value.lista[tem].jump = {}
          selectList.value.lista[tem].jump['_other'] = relation.value.to.id
          relation.value = {from:{id: '', texto:''}, to:{id: '', texto:''}}
          aviso(`Ok`, `Relacionado`)
        }
      }

    }
    
    // Salvar o flow
    const saveFlow = async () => {
      novaLista.value = selectList.value
      await addLista(novaLista.value, 'Salvar')
    }

    // Adiciona uma lista
    const addLista = async (salvar, op) => {
      let resp = {sts: false, data: {}}
      if (op === 'Incluir') {
        resp = await checkListCtrl.salvar(salvar)
      } else if (op === 'Salvar') {
        resp = await checkListCtrl.editar(salvar._id, salvar)
      } else if (op === 'Deletar') {
        resp = await checkListCtrl.deletar(salvar._id)
      }    
      if (resp.sts) {
          aviso(`Ok`, `${op} -> concluido`)
          await listCheck()
          selectList.value = []
          novaLista.value = await checkListCtrl.novo()
        } else {
          await aviso(`Erro`, `${op} -> ${resp.data.response.data.error}`)
      }
    }

    // Realizar o clear
    const clearLista = async () => {
      novaLista.value = await checkItemCtrl.novo()
      await listCheck()
      selectList.value = novaLista.value
      preview.value = false
      modoList.value = 'Incluir'
    }
    
    // Mostra o preview de uma lista
    const prevLista = async () => {
      questions.value = selectList.value.lista
      await openPreview() 
    }
    
    // Remover um item da lista
    const itemRmList = async (idx) => {
      const rel_id = selectList.value.lista[idx].rel_id
      selectList.value.lista = selectList.value.lista.filter(lst => lst.rel_id !== rel_id)
    }
    
    // Muda select da lista
    const changeList = async (novo) => {
      selectList.value = novo
      preview.value  = false
      modoList.value = 'Incluir'
    }

    // Modo de edicao para lista
    const editLista = async () => {
      novaLista.value = selectList.value
      modoList.value = 'Salvar'
    }

    // Obtem as listas de atividades
    const listCheck = async () => {
      itemsLista.value = await checkListCtrl.listar()
    }
    // Exibicao de alertas na tela
    const aviso = async (tela, titulo, mensagem) => {
        const alert = await alertController.create({
          header: tela,
          subHeader: titulo,
          message: mensagem,
          buttons: ['OK'],
        });
        await alert.present();
    };
    
    // Resolve as associações quando o fluxo tem opções
    const neastedloop = async(item, itm_id) => {
      const existe = selectList.value.lista.findIndex(obj => obj._id === item._id) 
      let neasted = []
      if (disarm.value) {
        neasted = []
      } else if (existe === -1) {
        item['rel_id'] = itm_id
        neasted.push(item)
        if (item.options.length > 0) {
          const res = await openModal(item, items.value)
          if (res.sts === 'confirm') {
            item = res.data
          } else {
            disarm.value = true
            neasted = []
          } 
        }
        for (let op of item.options) {
          if(op.value) {
            const tem = items.value.findIndex(obj => obj.id === op.value)
            if(tem > -1) {
                item.jump[op.label] = op.value
                op.referencia = op.value
                op.value = op.label
                let resp = await neastedloop(items.value[tem], itm_id)
                for(let r of resp) {
                  r['rel_id'] = itm_id
                  neasted.push(r)
                }
            }
          } else {
            op.value = op.label
          } 
        }
      } else {
        aviso('Erro', 'Check não incluido', 'Já existe')
      }
      return neasted
    }
    // Adicionar uma check em uma lista
    const doItemToList = async (item) => {     
      disarm.value = false 
      const news = await neastedloop(item, item._id)
      if (!disarm.value && news.length > 0) {
        for( let n of news) {
         // const toSv = checkItemCtrl.fromObj(n)
          selectList.value.lista.push(n)
        }
      }
    }
    // Exibir o modal para relacionamento dos fluxos
    const openModal = async (sel, itm) => {
      const id_modal = sel._id.toString()
      let modal = await modalController.create({
        component: modalAtiviade, componentProps: { selectItem: sel, items: itm },
        id: id_modal
      })
      modal.present()
      const { data, role } = await modal.onWillDismiss();
      await modalController.dismiss(undefined, undefined, id_modal)
      return {sts: role, data: data}  
    }

    const changeCheck = async (evt) => {
      if(evt.detail.checked) {
        if(!relation.value.from.id) {
          relation.value.from.id = evt.detail.value.id
          relation.value.from.nome = `${evt.detail.value.tagline}- ${evt.detail.value.title}` 
        } else if(!relation.value.to.id) {
          relation.value.to.id = evt.detail.value.id
          relation.value.to.nome = `${evt.detail.value.tagline}- ${evt.detail.value.title}`
        } else {
          evt.target.checked = false
        }
      } else {
        if(relation.value.from.id === evt.detail.value.id) {
          relation.value.from.id = ''
          relation.value.from.nome = ''
        } else if (relation.value.to.id === evt.detail.value.id) {
          relation.value.to.id = ''
          relation.value.to.nome = ''
        }
      }
    }
  
    // Exibir o modal para relacionamento dos fluxos
    const openPreview = async () => {
      const id_modal = `preview`
      let modal = await modalController.create({
        component: flow, componentProps: { questions: questions.value},
        id: id_modal
      })
      modal.present()
      const { data, role } = await modal.onWillDismiss();
      await modalController.dismiss(undefined, undefined, id_modal)
      return {sts: role, data: data}  
    }

    
    const handleChange =async (event) => {
        const query = event.target.value.toLowerCase()
        if(!query){
          await listItem()
          await listCheck()
          await listGrupos()
        } else {
          items.value = items.value.filter(d => (d._id.toString() + d.tagline.toLowerCase() + d.title.toLowerCase()).indexOf(query) > -1)
          itemsLista.value = itemsLista.value.filter(d => (d._id.toString() + d.nome.toLowerCase() + d.objetivo.toLowerCase()).indexOf(query) > -1)
        }
    }

    const openUserModal = async () => {
      const id_modal = `manutencao_operador`
      let modal = await modalController.create({
        component: modalAcesso, componentProps: { modo: 'desktop' },
        id: id_modal
      })
      modal.present()
      const { data, role } = await modal.onWillDismiss();
      await modalController.dismiss(undefined, undefined, id_modal)
      await listGrupos()
      
      return { sts: role, data: data }
    }

    const openAtividadeModal = async () => {
      const id_modal = `atividade_operador`
      let modal = await modalController.create({
        component: modalAtividade, componentProps: { send: 'oi' },
        id: id_modal
      })
      modal.present()
      const { data, role } = await modal.onWillDismiss();
      await modalController.dismiss(undefined, undefined, id_modal)
      await listGrupos()
      
      return { sts: role, data: data }
    }
    
    onMounted(async () => {
      novo.value = await checkItemCtrl.novo()
      novaLista.value = await checkListCtrl.novo()
      await listItem()
      await listCheck()
      await listGrupos()
    })

    return {
      modules: [Autoplay, Keyboard, Pagination, Scrollbar, Zoom],
      items,
      novo,
      listItem,
      addItem,
      changeItem,
      selectItem,
      editItem,
      delItem,
      modo,
      opcao,
      addOpcao,
      remOpcao,
      prevItem,
      clearItem,
      preview,
      aviso,
      novaLista,
      itemsLista,
      listCheck,
      changeList,
      selectList,
      itemRmList,
      doItemToList,
      prevLista,
      questions,
      changeCheck,
      relation,
      addLista,
      editLista,
      clearLista,
      modoList,
      saveFlow,
      relationFlow,
      clearRela,
      delLista,
      handleChange,
      grupoSel,
      grupoSelPerm,
      listaGrupos,
      addPermissao,
      remPermissao,
      remAutorizacao,
      chevronUpCircle,
      document,
      people,
      openUserModal,
      openAtividadeModal,
      addDefault,
      listCircle,
      editFromList,
      create,
      trash,
      defFlow,
      ordenar,
      dragEvt,
      addAutorizacao,
      barList,
      changeBar
    };
  },
});
</script>

<style >
  ion-label{
    white-space
    :normal !important;
  }
  .draggable {
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  cursor: move;
}
.ghost {
  opacity: 0.5;
  border: 1px dashed #ccc;
}

</style>