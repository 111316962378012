<template>
        
        <div  v-show="pdf_show" ref="cont_pdf"  class="box_pdf">
            <h2>{{sel_atvs.atividade}}</h2>
            <h2>{{sel_atvs.op.nome}} - {{fmtData(sel_atvs.data_fim)}}</h2>
            <table>
                <tr>
                    <th>Etapa</th>
                    <th>Atividade</th>
                    <th>Pergunta</th>
                    <th>Resposta</th>
                </tr>
                <tr  v-for="(qst, index) in sel_atvs.formulario.questions" v-bind:key="'m' + index">
                    <td>{{qst.etapa}}</td>
                    <td>{{qst.atividade}}</td>
                    <td>{{qst.pergunta}}</td>
                    <td>{{qst.resp}}</td>
                </tr>
            </table>
        </div>
        
        
        <ion-content class="ion-padding">
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>{{sel_atvs.atividade}}</ion-card-title>
                        <ion-card-subtitle>{{sel_atvs.op.nome}} - {{fmtData(sel_atvs.data_fim)}}</ion-card-subtitle>
                        
                        <ion-button @click="closepdf">
                            <ion-icon :icon="icons.backspace"></ion-icon>
                        </ion-button>
                        <ion-button @click="openpdf">
                            <ion-icon :icon="icons.document"></ion-icon>
                        </ion-button>
                        
                    </ion-card-header>
                    
                    <ion-card-content>
                        <ion-row>
                            <ion-col><ion-text>Etapa</ion-text></ion-col>
                            <ion-col><ion-text>Atividade</ion-text></ion-col>
                            <ion-col><ion-text>Pergunta</ion-text></ion-col>
                            <ion-col><ion-text>Resposta</ion-text></ion-col>
                        </ion-row>
                    
                        <ion-row v-for="(qst, index) in sel_atvs.formulario.questions" v-bind:key="'m' + index">
                            <ion-col><ion-text text-wrap>{{qst.etapa}}</ion-text></ion-col>
                            <ion-col><ion-text text-wrap>{{qst.atividade}}</ion-text></ion-col>
                            <ion-col><ion-text text-wrap>{{qst.pergunta}}</ion-text></ion-col>
                            <ion-col><ion-text text-wrap>{{qst.resp}}</ion-text></ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            
        </ion-content>
   
</template>

<script >
    import { backspace, document } from 'ionicons/icons'
   import html2pdf from 'html2pdf.js'
    import {
        IonCol,
        IonRow,
        IonCardTitle,
        IonCardSubtitle,
        IonCardHeader,
        IonText,
        IonCardContent,
        IonCard,
        IonButton,
        IonIcon,
        modalController
    } from '@ionic/vue';
    

export default {
    name: 'pdfShow',
    components: {
        IonCol,
        IonRow,
        IonCardTitle,
        IonCardSubtitle,
        IonCardHeader,
        IonText,
        IonCardContent,
        IonCard,
        IonButton,
        IonIcon
    },
    props: ['sel_atvs'],
    data() {
        return {
            icons: {backspace,document},
            pdf_show: false
        }
    },
    methods: {
        closepdf() {
            return modalController.dismiss(undefined, 'cancel');
        },
        
        fmtData(dtentrada)  {
            const dt = new Date(dtentrada)
            return dt.toLocaleString("pt-Br",{dateStyle: "short",timeStyle: "short",timeZone: "America/Sao_Paulo"})
        },
       
        async openpdf() {
            this.pdf_show = true
            const opt = {
                margin:       1,
                filename:     'atividade.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2},
                jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: 'avoid-all', before: '#page2el' }
            }
            await html2pdf().set(opt).from(this.$refs.cont_pdf).save()
            this.pdf_show = false
            
        }
    }

}
</script>

<style scoped>
    .box_pdf{
        background-color: #FFFFFF;
        color: #000000;
    }
    th, td {
        padding: 10px;
    }
    th {
        background-color: #c1c0bd;
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

</style>