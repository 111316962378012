class Operador {
    constructor() {
      this.id     = ''
      this._id    = ''
      this.nome   = ''
      this.senha  = ''
      this.code   = ''
      this.grupos = []
    }

    fromObj(obj) {
        this.id       = obj.id
        this._id       = obj._id
        this.nome     = obj.nome
        this.grupos   = obj.grupos
        return this
    }

    valid() {
        const resp = {sts: false, data: ''}
        if (!this.id) {
            resp.data = `ID obrigatorio`
        } else if (!this.nome) {
            resp.data = `NOME obrigatorio`
        } else if (!this.senha) {
            resp.data = `Senha obrigatorio`
        } else {
            resp.sts = true
        }
        return resp
    }
}  
export {Operador}
