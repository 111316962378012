import { ObjectID } from 'bson'

class CheckItem {
    constructor() {
      this._id      = new ObjectID()
      this.id       = ''
      this.type     = ''
      this.tagline  = ''
      this.title    = ''
      this.subtitle = ''
      this.required = true
      this.model    = ''
      this.options  = []
      this.jump     = {}
      this.api      = ''
      this.maxLength    = null
      this.mask         = null
      this.placeholder  = null
      this.barCode    = {enable: false, type: ''}
    }

    fromObj(obj) {
        this._id            = obj._id
        this.id             = obj.id
        this.type           = obj.type
        this.tagline        = obj.tagline
        this.title          = obj.title
        this.subtitle       = obj.subtitle
        this.required       = obj.required
        this.model          = obj.model
        this.options        = obj.options ? obj.options : []
        this.jump           = obj.jump ? obj.jump : {}
        this.api            = obj.api
        this.maxLength      = obj.maxLength
        this.mask           = obj.mask
        this.placeholder    = obj.placeholder
        this.barCode        = obj.barCode ? obj.barCode : {enable: false, type: ''}
        return this
    }

    valid() {
        const resp = {sts: false, data: ''}
        if (!this.id) {
            resp.data = `ID unico obrigatorio`
        }
        else if (!this.type) {
            resp.data = `Tipo obrigatorio`
        } else if (!this.tagline) {
            resp.data = `Atividade obrigatorio`
        } else if (!this.title) {
            resp.data = `Etapa obrigatorio`
        } else if (!this.subtitle) {
            resp.data = `Descricao obrigatorio`
        } else if(this.type === `Dropdown` && !this.api) {
            resp.data = `URL api obrigatorio`
        }else {
            resp.sts = true
        }
        return resp
    }
}  
export {CheckItem}
