import {get, post, del, edit} from '../service/api'
import {CheckList} from '../model/checklist'

const api_url = `${process.env.VUE_APP_API}/checklist`

const checkListCtrl = {
    
    fromObj: (from) => (new CheckList()).fromObj(from),

    novo: async () => new CheckList(),
    
    obter: async (id) => {
        const lista = []
        const resp = await get(`${api_url}?id=${id}`)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new CheckList().fromObj(check))
            }
        }
        return lista  
    },
    
    listar: async () => {
        const lista = []
        const resp = await get(api_url)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new CheckList().fromObj(check))
            }
        }
        return lista
    },
    
    salvar: async (checklist) => {
        const valido = checklist.valid()
        if (valido.sts) {
            return await post(api_url, checklist)
        } else {
            return valido
        }
    },
    
    editar: async (id, novo) => {
        const valido = novo.valid()
        if (valido.sts) {
            const resp = await edit(`${api_url}/${id}`, novo)
            return resp
        } else {
            return valido
        }
    },

    deletar: async (id) => {
        const resp = await del(`${api_url}/${id}`)
        return resp
    }

}

export {checkListCtrl}
