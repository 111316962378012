<template>
        <ion-content>
           
                    <ion-card>
                        <ion-card-content>
                            <ion-item>
                                <ion-label>{{operador.nome}}</ion-label>
                            </ion-item>
                            <ion-item v-if="!tem_atividade && operador.nome">
                                <ion-select v-model="select_atividade" placeholder="Atividades" interface="popover">
                                    <ion-select-option v-for="itm in lista" :key="itm.id" :value="itm">
                                        {{ itm.nome }} - {{itm.objetivo}}
                                    </ion-select-option>
                                </ion-select>
                            </ion-item>
                            <ion-button v-if="!tem_atividade && operador.nome" expand="full" shape="round" @click="iniciar()">Iniciar</ion-button>
                        </ion-card-content>
                    </ion-card>
        
            
            <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                <ion-fab-button size="large">
                    <ion-icon size="large" :icon="chevronUpCircle"></ion-icon>
                </ion-fab-button>
                <ion-fab-list side="top">
                    <ion-fab-button  @click="startScan">
                        <ion-icon size="large" :icon="barcode"></ion-icon>
                    </ion-fab-button>
                    <ion-fab-button v-if="operador.nome" @click="reload">
                        <ion-icon size="large" :icon="document"></ion-icon>
                    </ion-fab-button>
                    <ion-fab-button @click="doAcesso">
                        <ion-icon size="large" :icon="operador.nome ? logOut : people"></ion-icon>
                    </ion-fab-button>
                    <ion-fab-button  v-if="!operador.nome" @click="openModal">
                        <ion-icon size="large" :icon="globe"></ion-icon>
                    </ion-fab-button>
                    <ion-fab-button  v-if="operador.nome" @click="takePicture">
                        <ion-icon size="large" :icon="camera"></ion-icon>
                    </ion-fab-button>
                    <ion-fab-button  v-if="operador.nome" @click="listen">
                        <ion-icon size="large" :icon="megaphone"></ion-icon>
                    </ion-fab-button>
                </ion-fab-list>
            </ion-fab>
        
            <div v-if="tem_atividade">
                <flow-form ref="flowform" v-bind:language="language" v-on:complete="onComplete" v-bind:progressbar="false">
        
                    <question v-for="(question, index) in questions" v-bind="question" v-bind:key="'m' + index"
                        v-model="question.model" />
        
                    <template v-slot:complete>
                        <div class="f-section-wrap">
                        <h2>{{msg_final}}</h2>
                        <ion-button  fill="clear" shape="round" @click="reload()">OK</ion-button>    
                    </div>
                    </template>
                </flow-form>
        
            </div>
        </ion-content>
</template>
  
<script>
    import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
    import { Camera, CameraResultType } from '@capacitor/camera'
    import { SpeechRecognition } from "@capacitor-community/speech-recognition";
    import { IonContent, IonSelectOption, IonSelect, alertController, modalController,
    IonLabel,
        IonItem,
        IonButton,
        IonCardContent,
        IonCard,
        IonIcon,
        IonFabButton,
        IonFabList,
        IonFab
} from '@ionic/vue'
    import { operadorCtrl } from "../controller/operadorController"
    import { realizadosCtrl } from "../controller/realizadosController"
    import modalAcesso from '../components/acesso.vue'
    import { chevronUpCircle, document, globe, people, logOut, camera, barcode, megaphone } from 'ionicons/icons'
    import { ref, onMounted, onBeforeUnmount } from 'vue';
    import { get } from '../service/api'
    import { FlowForm, Question, /*QuestionModel, QuestionType,*/ /*ChoiceOption,*/ LanguageModel } from '@ditdot-dev/vue-flow-form'

    export default {
        name: 'mobileFlow',
        components: {FlowForm, Question, IonContent, IonSelectOption, IonSelect,
            IonLabel,
        IonItem,
        IonButton,
        IonCardContent,
        IonCard,
        IonIcon,
        IonFabButton,
        IonFabList,
        IonFab},
        setup() {
            const flowform = ref(null);
            const data_inicio = ref(new Date())
            const operador = ref({id:'', nome:''})
            const select_atividade = ref('')
            const questions =  ref([])
            const tem_atividade = ref(false)
            const lista = ref([])
            const msg_final = ref(``)
            const language = ref(new LanguageModel({
                    multipleChoiceHelpTextSingle: "",
                    pressEnter: "",
                    skip: "Proximo",
                    thankYouText : "Tarefa realizada",
                    submitText: "Salvar",
                    successText: "",
                    placeholder: "",
                    longTextHelpText: "",
                    invalidPrompt : "Preenchimento inválido",
            }))

            const takePicture = async () => {
                const image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: true,
                    resultType: CameraResultType.Uri
                });
                // image.webPath will contain a path that can be set as an image src.
                // You can access the original file using image.path, which can be
                // passed to the Filesystem API to read the raw data of the image,
                // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
                console.log(image.webPath)
                // Can be set to the src of an image now
                // imageElement.src = imageUrl;
            }

            const startScan = async () => {
                // Check camera permission
                // This is just a simple example, check out the better checks below
                await BarcodeScanner.checkPermission({ force: true });
                // make background of WebView transparent
                // note: if you are using ionic this might not be enough, check below
                await BarcodeScanner.hideBackground();
                const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.CODE_128] })
                // if the result has content
                if (result.hasContent) {
                    console.log(result.content); // log the raw scanned content
                }
            }
            const stopScan = () => {
                BarcodeScanner.showBackground();
                BarcodeScanner.stopScan();
            }

            const listen = () => {
                if (!SpeechRecognition.hasPermission()) {
                    SpeechRecognition.requestPermission()
                }
                const match = SpeechRecognition.start({
                        language: "pt-BR",
                        maxResults: 2,
                        prompt: "Fale...",
                        partialResults: false,
                        popup: true,
                    }) 
                console.log(match)
                SpeechRecognition.stop()
                return match
            }

            const setUsuario = async (cracha, senha, code) => {
                if(code) {
                    const trocaSenha = await operadorCtrl.havecode(cracha, code)
                    if (trocaSenha) {
                        await operadorCtrl.editar(cracha, {senha: senha, code: ''})
                        alert('Senha alterada')
                    }
                }
                const op = await operadorCtrl.login(cracha, senha)
                if (op.length > 0) {
                    operador.value = op[0]
                    if(operador.value.nome) {
                        await getAtividade(operador.value)
                    }
                } else {
                    operador.value = {id:'', nome:''}
                    alert('Identificação ou senha inválida')
                }
                
            }

            const getAtividade = async (op) => {
                const resp = await get(`${process.env.VUE_APP_API}/checklist`)
                if (resp.sts) {
                    for(let perm of resp.data) {
                        for(let us of op.grupos){
                            if(perm.permissao.some(pr => pr.id === us)) {
                                lista.value.push(perm)
                            }
                        }
                    }
                } else {
                    alert(resp.data)
                }
            }

            const doAcesso = async () => {
                if(operador.value.nome) {
                    operador.value =  {id:'', nome:''}
                    localStorage.removeItem('user')
                    localStorage.removeItem('token')
                    reload()
                } else {
                    await presentAlert()
                }
            }
            const presentAlert = async () => {
                const alert = await alertController.create({
                header: 'Identifique-se',
                buttons:[{
                text: 'Ok',
                    handler: data => setUsuario(data.identificacao, data.senha, data.code) 
                }],
                inputs: [
                    {name:'identificacao', placeholder: 'Identificação', type:"text"},
                    {name:'senha', placeholder: 'senha', type: "password"},
                    {name:'code', placeholder: 'code', type: "text"}]
                    
                });
                await alert.present();
             }

             const iniciar = () => {
                questions.value = select_atividade.value.lista
                tem_atividade.value = true
             }
             const reload = async () => {
                flowform.value.questionList = null
                for(let q of flowform.value.questionModels ) {
                    q.resetAnswer()
                }
                questions.value = []
                tem_atividade.value = false
             }
             const openModal = async () => {
                const id_modal = `operador`
                let modal = await modalController.create({
                    component: modalAcesso, componentProps: { modo: 'mobile' },
                    id: id_modal
                })
                modal.present()
                const { data, role } = await modal.onWillDismiss();
                if(role === `confirm`) {
                    console.log(data)
                    if(!data.sts) {
                        alert(data.data)
                    } else {
                        alert('OK - Solicitado - Aguarde liberação de grupos')
                    }
                }
                await modalController.dismiss(undefined, undefined, id_modal)
                return {sts: role, data: data}  
            }

            const onComplete = async () => {
                flowform.value.submitted = true
                const tarefa        = await realizadosCtrl.novo()
                tarefa.op           = operador.value
                tarefa.atividade    = select_atividade.value.nome
                tarefa.data_fim     = new Date()
                tarefa.data_inicio  = data_inicio.value
                const form          = getData()
                tarefa.formulario   = {questions: form.questions}
                tarefa.tag_busca      = form.tag_busca

                const salvo = await realizadosCtrl.salvar(tarefa)
                if(salvo.sts) {
                    msg_final.value = `Lista salva`
                } else {
                    msg_final.value = salvo.data
                }
                
            }

            const getData = () => {
                const data = {questions: [], tag_busca: ''}
                questions.value.forEach(question => {
                if (question.title) {
                    let answer = question.model
                    if (Array.isArray(answer)) {
                        answer = answer.join(', ')
                    }
                    if (question.tagline.toLowerCase().startsWith('#')) {
                        data.tag_busca = `${data.tag_busca}-${answer}`
                    }
                    data.questions.push({etapa: question.title,atividade: question.tagline, pergunta: question.subtitle, resp: answer})
                }
                })
                return data
            }

            onMounted(async () => {
                BarcodeScanner.prepare()
                if (!SpeechRecognition.hasPermission()) {
                    SpeechRecognition.requestPermission()
                }
            })
            onBeforeUnmount(async () => {
                BarcodeScanner.showBackground()
                BarcodeScanner.stopScan()
            })
            return {
                chevronUpCircle,  document, globe, people, camera, barcode,megaphone, presentAlert,
                operador, iniciar,select_atividade, questions,tem_atividade,reload,flowform,lista,
                openModal,logOut, doAcesso, onComplete, getData, language,msg_final, takePicture, startScan, stopScan, listen
            }
        }
    }
</script>
  
<style scoped>
    @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
    @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';

    ion-fab-button {
        width: 80px;
        height: 80px;
    }
</style>