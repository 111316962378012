<template>
    <div class="ion-page">
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title>Login</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ion-grid>
                <ion-row justify-content-center>
    
                    <ion-col align-self-center size-md="3" size-lg="3" size-xs="6"></ion-col>
                    <ion-col align-self-center size-md="6" size-lg="6" size-xs="12">
                        <div padding>
                            <ion-item>
                                <ion-label position="stacked" color="primary">Usuario</ion-label>
                                <ion-input v-model="logInfo.username" name="username" type="text" spellcheck="false"
                                    autocapitalize="off" required></ion-input>
                            </ion-item>

                            <ion-item>
                                <ion-label position="stacked" color="primary">Senha</ion-label>
                                <ion-input v-model="logInfo.password" name="password" type="password" required></ion-input>
                            </ion-item>
                        </div>

                        <div padding>
                            <ion-button @click="onLogin()" expand="block">Login</ion-button>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>

        </ion-content>
    </div>
</template>


<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores'
import {
  IonCol, 
  IonRow,
  IonButton,
  IonLabel,
  IonItem,
  IonToolbar,
  IonContent,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonInput,
  IonHeader,
  IonGrid,
  toastController
} from '@ionic/vue';

const logInfo = ref({username:'', password: ''})

const presentToast = async (texto) => {
        const toast = await toastController.create({
          message: texto,
          duration: 1500,
          position: 'bottom',
          color: 'danger'
        });
        await toast.present();
}


const onLogin = () => {
    const authStore = useAuthStore()
    const { username, password } = logInfo.value
    if(!username || ! password) {
        presentToast('Usuario e senha obrigatorios')
    } else {
        return authStore.login(username, password, presentToast)   
    }
}
</script>


<style scoped>
.list {
  margin-bottom: 0;
}
</style>