import { createRouter, createWebHistory } from '@ionic/vue-router';
import { useAuthStore } from '@/stores';
import HomePage from '../views/HomePage.vue'
import MobilePage from '../views/MobileFlow.vue'
import LoginView from '../views/LoginView.vue'
import Atividade from '../components/atividade.vue'

const routes = [
  {
    path: '/',
    redirect: `${process.env.VUE_APP_MAIN === '/home'? '/consulta': process.env.VUE_APP_MAIN}`
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/consulta',
    name: 'Consulta',
    component: Atividade
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: MobilePage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  if(process.env.VUE_APP_MAIN === '/home') {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        next({ name: 'Login' })
        return '/login';
    } else {
      next()
    }
  }
})


export default router
