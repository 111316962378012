import axios from "axios"
import router from '../router'

axios.interceptors.request.use(
    config => {
      config.headers['authorization'] = `${localStorage.getItem('token')}`
          return config
      },
      error => {
          return Promise.reject(error)
      }
)

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status == 403) {
            if(process.env.VUE_APP_MAIN === '/home') {
                router.push('/login')
            }
        }
    }
)

const get = async (url) => {
    try {
      const res = await axios.get(url, )
      return { sts: true, data: res.data }
    } catch (error) {
        return { sts: false, data: error }
    }
}

const post = async (url, body) => {
    try {
      const res = await axios.post(url, body)
      return { sts: true, data: res.data }
    } catch (error) {
        return { sts: false, data: error }
    }
}

const del = async (url) => {
    try {
      const res = await axios.delete(url)
      return { sts: true, data: res.data }
    } catch (error) {
        return { sts: false, data: error }
    }
}

const edit = async (url, body) => {
    try {
      const res = await axios.patch(url, body)
      return { sts: true, data: res.data }
    } catch (error) {
        return { sts: false, data: error }
    }
}

export {
    get,
    post,
    del,
    edit
}
