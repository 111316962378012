import {get, post, del, edit} from '../service/api'
import {Operador} from '../model/operador'

const baseUrl = `${process.env.VUE_APP_API_ROOT}`
const api_url = `${process.env.VUE_APP_API}/operadores`


const operadorCtrl = {
    
    fromObj: (from) => (new Operador()).fromObj(from),

    novo: async () => new Operador(),
    
    
    havecode: async (id, code) => {
        let have = false
        const bUser = { 
            usuario: id, 
            senha : code
        }
        const user = await post(`${baseUrl}/auth/havecode`, bUser)
        if (user.sts) {
            localStorage.setItem('token', user.data.token)
            have = (user.data.length > 0)
        }
        return have  
    },
    
    login: async (id, senha) => {
        const lista = []
        const bUser = { 
            usuario: id, 
            senha : senha
        }
        const user = await post(`${baseUrl}/auth/login`, bUser)
        if (user.sts) {
            localStorage.setItem('token', user.data.token)
            localStorage.setItem('user', JSON.stringify(user.data))
            const resp = await get(`${api_url}?id=${id}&senha=${senha}`)
            if (resp.sts) {
                for (let check of resp.data) {
                    lista.push(new Operador().fromObj(check))
                }
            }
        }
        return lista  
    },
    
    obter: async (id) => {
        const lista = []
        const resp = await get(`${api_url}?id=${id}`)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new Operador().fromObj(check))
            }
        }
        return lista  
    },
    
    listar: async () => {
        const lista = []
        const resp = await get(api_url)
        if (resp.sts) {
            for (let check of resp.data) {
                lista.push(new Operador().fromObj(check))
            }
        }
        return lista
    },
    
    salvar: async (operador) => {
        const valido = operador.valid()
        if (valido.sts) {
            const existe = await get(`${api_url}?id=${operador.id}`)
            if (existe.sts) {
                if( existe.data.length > 0 ) {
                    return { sts: false, data: `id ${operador.id} ja existe` }
                } else {
                    return await post(api_url, operador)
                }
            }
            return existe
        } else {
            return valido
        }
    },
    
    editar: async (id, novo) => {
        const resp = await edit(`${api_url}/${id}`, novo)
        return resp
    },

    deletar: async (id) => {
        const resp = await del(`${api_url}/${id}`)
        return resp
    }

}

export {operadorCtrl}
